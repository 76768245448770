require('../vendor/html5shiv.min');
require('../vendor/es5-shim.min');
require('../vendor/json2');

// polyfills

// Source: https://github.com/Alhadis/Snippets/blob/master/js/polyfills/IE8-child-elements.js
if (!('nextElementSibling' in document.documentElement)) {
    Object.defineProperty(Element.prototype, 'nextElementSibling', {
        get: function () {
            let e = this.nextSibling;
            while (e && e.nodeType !== 1) { e = e.nextSibling; }
            return e;
        }
    });
}

